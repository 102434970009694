import {methods} from 'underscore';

export default (fn, key) => ({
  data() {
    return {
      [key]: undefined,
    };
  },
  mounted() {
    this[`fetch${key}`]();
  },
  methods: {
    async [`fetch${key}`]() {
      this[key] = await fn();
    },
  },
});
